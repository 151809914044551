import React from "react";
import { Divider, notification, Input, Button, Modal, Tag, Breadcrumb } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { FaTrashAlt, FaEye, FaTrashRestoreAlt } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Auxiliary from "util/auxiliary";
import {
  listAppUser, deleteUser, searchAppUser, resetUser
} from "../../../appRedux/actions/appUsers";

const { Search } = Input;
const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}
class AppUsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      userList: [],
      pagination: {
        current: 1,
        pageSize: 10
      }
    }

    this.columns = [{
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: (a, b) => a.firstName.length - b.firstName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: (a, b) => a.lastName.length - b.lastName.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ['descend', 'ascend']
    }, {
      title: 'Mobile Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => { return (<span>{phone}</span>) }
    }, {
      title: 'Mobile Verified',
      dataIndex: 'isMobileVerified',
      key: 'isMobileVerified',
      sorter: (a, b) => a.isMobileVerified - b.isMobileVerified,
      render: (isMobileVerified) => {
        return (<Tag color={isMobileVerified ? "warning" : 'processing'}>
          {isMobileVerified ? 'Yes' : 'No'}
        </Tag>)
      }
    }, {
      title: 'Status',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      width: 100,
      sorter: (a, b) => a.isDeleted - b.isDeleted,
      sortDirections: ['descend', 'ascend'],
      render: (isDeleted) => {
        return (<Tag color={isDeleted ? "warning" : 'processing'}>
          {isDeleted ? 'Deleted' : 'Active'}
        </Tag>)
      },
      filters: [
        {
          text: 'Active',
          value: false
        },
        {
          text: 'Deleted',
          value: true
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.isDeleted === value
    }, {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => { return (<span>{moment(createdAt).format('MM-DD-YYYY hh:mm a')}</span>) }
    }, {
      title: 'Action',
      key: 'action',
      width: 100,
      render: (text, record) => (
        <span>
          <span title="Preview" className="gx-link">
            <Link to={`/customers/preview/${record._id}`}>
              <FaEye className="icon-size" />
            </Link>
          </span>
          <Divider type="vertical" />
          {!record.isDeleted ?
            <span title="Delete" className="gx-link delete-color" onClick={(e) => { this.onConfirmDelete(record); }}>
              <FaTrashAlt className="icon-size" />
            </span>
            :
            <span title="Restore" className="gx-link delete-color" onClick={(e) => { this.onConfirmRestore(record); }}>
              <FaTrashRestoreAlt className="icon-size" />
            </span>
          }
        </span>
      ),
    }]

  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.appUsers) {
      if (nextProps.appUsers.messageId === 204) {
        return {
          userList: nextProps.appUsers.lists.data,
          pagination: {
            current: nextProps.appUsers.lists.current,
            pageSize: nextProps.appUsers.lists.pageSize,
            total: nextProps.appUsers.lists.totalCount
          }
        }
      } else if (nextProps.appUsers.messageId === 200) {
        notification['success']({
          message: 'Success',
          description: nextProps.appUsers.alertMessage
        });
        let pagination = state.pagination;
        state.currentInstance.fetch({ pagination });
        nextProps.resetUser();
      } else if (nextProps.appUsers.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.appUsers.alertMessage
        });
        nextProps.resetUser();
      }
      return true;
    }
    return null;
  }

  onConfirmDelete(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Delete',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to delete ' + record.firstName + ' ' + record.lastName + '?',
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onDeleteRecord
      });
    })
  }

  onDeleteRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: true
    }
    this.props.deleteUser(authUser.token, data);
  }

  onConfirmRestore(record) {
    this.setState({
      record: record
    }, () => {
      Modal.confirm({
        title: 'Restore',
        centered: true,
        icon: <ExclamationCircleOutlined />,
        content: 'Are you sure you want to restore ' + record.firstName + ' ' + record.lastName + '?',
        okText: 'Restore',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk: this.onRestoreRecord
      });
    })
  }

  onRestoreRecord = () => {
    const { record } = this.state;
    const { authUser } = this.props.auth;
    let data = {
      _id: record._id,
      isDeleted: false
    }
    this.props.deleteUser(authUser.token, data);
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      if (pageInfo.isDeleted && pageInfo.isDeleted.length > 0) {
        inputJson.isDeleted = pageInfo.isDeleted[0] ? 'TRUE' : 'FALSE';
      }
      this.props.listAppUser(authUser.token, inputJson);
    }
  }

  onSearch = value => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        searchString: value,
        current: 1,
        pageSize: pagination.pageSize
      }
      const { authUser } = this.props.auth;
      this.props.searchAppUser(inputJson, authUser.token);
    }
  };

  onChange = e => {
    if (e.type === 'click') {
      const { pagination } = this.state;
      this.fetch({ pagination });
    }
  };

  render() {
    const { loader } = this.props.appUsers;
    const { pagination, userList } = this.state;
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Customer Accounts</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="sumission-title">Customer Accounts</span>
          </Col>
        </Row>
        <>
          <Row className="row-margin2">
            <Col>
              <Search
                placeholder="Search by First Name or Last Name or Email or Mobile Phone"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={this.onSearch}
                onChange={this.onChange}
              />
            </Col>
          </Row>
          <Row className="row-margin2">
            <Col>
              <Table
                className="gx-table-responsive"
                columns={this.columns}
                dataSource={userList}
                rowKey={record => record._id}
                pagination={{
                  ...pagination,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  itemRender: itemRender,
                  size: 'small'
                }}
                loading={loader}
                size={'middle'}
                onChange={this.handleTableChange}
              />
            </Col>
          </Row>
        </>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appUsers: state.appUsers
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({ listAppUser, deleteUser, searchAppUser, resetUser }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(AppUsersList);
