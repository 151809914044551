import React from "react";
import { notification, Modal, Tag, Breadcrumb, Input, Button } from "antd";
import { Table } from "ant-table-extensions";
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import { FaEye } from 'react-icons/fa';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ToolTip from "components/ToolTip";
import PageHeader from "components/PageHeader";
import Auxiliary from "util/auxiliary";

import {
  listPersonalizations,
  searchPersonalization,
  resetGamePersonalizations
} from "../../../appRedux/actions/personalization";

const { Search } = Input;

const getRandomuserParams = params => ({
  results: params.pagination.pageSize,
  page: params.pagination.current,
  ...params,
});

const itemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <Button className="ant-paginatio-button"><MdNavigateBefore className="icon-size" /> Previous</Button>;
  }
  if (type === 'next') {
    return <Button className="ant-paginatio-button">Next <MdNavigateNext className="icon-size" /></Button>;
  }
  return originalElement;
}

const gameStatus = (status) => {
  let statusTag;
  switch (status) {
    case "Schedule":
      statusTag = <Tag color="#108ee9">Scheduled</Tag>
      break;
    case "Sent":
      statusTag = <Tag color="#87d068">Sent</Tag>
      break;
    default:
      statusTag = <Tag color="#2db7f5">Draft</Tag>
      break;
  }
  return statusTag;
}

const renderPlayingStatus = (status) => {
  let statusTag;
  switch (status) {
    case 1:
      statusTag = <Tag color="processing">In Progress</Tag>
      break;
    case 2:
      statusTag = <Tag color="warning">Paused</Tag>
      break;
    case 3:
      statusTag = <Tag color="success">Completed</Tag>
      break;
    case 4:
      statusTag = <Tag color="geekblue">Tokkenz.shop</Tag>
      break;
    default:
      statusTag = <Tag color="default">Not Started</Tag>
      break;
  }
  return statusTag;
}

class GameTracking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentInstance: this,
      record: null,
      personalizationList: [],
      pagination: {
        current: 1,
        pageSize: 10
      },
      previewDetails: false,
      previewDetailsTitle: null,
      previewDetailsData: null,
    }

    this.columns = [{
      title: 'Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortDirections: ['descend', 'ascend'],
      render: (createdAt) => {
        return (
          <>
            <span>{moment(createdAt).format('MM-DD-YYYY')}</span>
            <br />
            <span>{moment(createdAt).format('hh:mm a')}</span>
          </>
        )
      }
    }, {
      title: 'Game ID#',
      dataIndex: 'gameNumber',
      key: 'gameNumber',
      width: 150,
      sorter: (a, b) => a.gameNumber - b.gameNumber,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender',
      width: 150,
      sorter: (a, b) => a.sender.firstName.length - b.sender.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (sender) => {
        return (
          <>
            <span>{sender?.firstName} {sender?.lastName}</span>
            <br />
            <span>{sender?.email}</span>
            <br />
            <span>{sender?.dialCode} {sender?.phone}</span>
          </>
        )
      }
    },
    {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient',
      width: 150,
      sorter: (a, b) => a.recipient.firstName.length - b.recipient.firstName.length,
      sortDirections: ['descend', 'ascend'],
      render: (recipient) => {
        return (
          <>
            <span>{recipient?.firstName} {recipient?.lastName}</span>
            <br />
            <span>{recipient?.email}</span>
            <br />
            <span>{recipient?.dialCode} {recipient?.phone}</span>
          </>
        )
      }
    },
    {
      title: 'Game',
      dataIndex: 'inventoryId',
      key: 'inventoryId',
      width: 200,
      sorter: (a, b) => a.inventoryId?.gameName?.length - b.inventoryId?.gameName?.length,
      sortDirections: ['descend', 'ascend'],
      render: (inventoryId) => {
        return (<span>{inventoryId?.gameName}</span>)
      }
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status - b.status,
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        return (gameStatus(status))
      },
      filters: [
        {
          text: 'Draft',
          value: 'Draft',
        },
        {
          text: 'Scheduled',
          value: 'Schedule',
        },
        {
          text: 'Sent',
          value: 'Sent',
        }
      ],
      onFilter: (value, record) => record.status === value
    }, {
      title: 'Playing',
      dataIndex: 'playingStatus',
      key: 'playingStatus',
      sorter: (a, b) => a.playingStatus - b.playingStatus,
      sortDirections: ['descend', 'ascend'],
      render: (playingStatus) => {
        return (renderPlayingStatus(playingStatus))
      },
      filters: [
        {
          text: 'Not Started',
          value: 0,
        },
        {
          text: 'In Progress',
          value: 1,
        },
        {
          text: 'Paused',
          value: 2,
        },
        {
          text: 'Completed',
          value: 3,
        },
        {
          text: 'Tokkenz.shop',
          value: 4,
        }
      ],
      onFilter: (value, record) => record.playingStatus === value
    }, {
      title: 'Action',
      key: 'action',
      width: 150,
      render: (record) => (
        <>
          <ToolTip
            text="See Details"
            child={
              <span className="gx-link" onClick={(e) => { this.onPreviewDetails(record); }}>
                <FaEye className="icon-size gx-link" />
              </span>
            }
          />
        </>
      ),
    }];
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.fetch({ pagination });
  }

  fetch = (params = {}) => {
    let pageInfo = getRandomuserParams(params);
    const { authUser } = this.props.auth;
    if (authUser && pageInfo) {
      let inputJson = {
        current: pageInfo.pagination.current,
        pageSize: pageInfo.pagination.pageSize
      }
      this.props.listPersonalizations(authUser.token, inputJson);
    }
  }

  onSearch = value => {
    if (value) {
      const { pagination } = this.state;
      let inputJson = {
        searchString: value,
        current: pagination.current,
        pageSize: pagination.pageSize
      }
      const { authUser } = this.props.auth;
      this.props.searchPersonalization(inputJson, authUser.token);
    }
  };

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.personalizationData) {
      if (nextProps.personalizationData.messageId === 200) {
        nextProps.resetGamePersonalizations();
        return {
          personalizationList: nextProps.personalizationData.lists.data,
          pagination: {
            current: nextProps.personalizationData.lists.current,
            pageSize: nextProps.personalizationData.lists.pageSize,
            total: nextProps.personalizationData.lists.totalCount
          }
        }
      } else if (nextProps.personalizationData.messageId === 203) {
        notification['error']({
          message: 'Alert!!',
          description: nextProps.personalizationData.alertMessage
        });
        nextProps.resetGamePersonalizations();
      }
      return true;
    }
    return null;
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters
    });
  };

  onPreviewDetails = (record) => {
    this.setState({
      previewDetailsData: record,
      previewDetails: true,
      previewDetailsTitle: record.gameNumber
    });
  }

  onDownloadReceipt = (record, e) => {
    e.preventDefault();
    window.open(record.receiptUrl, '_blank');
  }

  handleCancel = () => this.setState({
    previewDetails: false
  });

  render() {
    const { isLoading } = this.props.personalizationData;
    const { pagination, personalizationList, previewDetails, previewDetailsTitle, previewDetailsData } = this.state;
    let cashGift;
    if (previewDetailsData) {
      let filterData = _.filter(previewDetailsData.orderId.items, function (o) {
        return o.gameNumber === previewDetailsData.gameNumber;
      });
      cashGift = filterData[0].cashGift;
    }
    return (
      <Auxiliary>
        <Row className="margin-bottom">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/home/dashboard" className="gx-link">Dashboard</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Game Tracking</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <PageHeader title="Game Tracking" />
        <Row className="row-margin2">
          <Col>
            <Search
              placeholder="Search by Game#"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={this.onSearch}
            />
          </Col>
        </Row>
        <Row className="row-margin2">
          <Col>
            <Table
              className="gx-table-responsive"
              columns={this.columns}
              dataSource={personalizationList}
              rowKey={record => record._id}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                itemRender: itemRender,
                size: 'small'
              }}
              loading={isLoading}
              size={'middle'}
              onChange={this.handleTableChange}
            />
          </Col>
        </Row>
        <Modal
          visible={previewDetails}
          title={"Game ID# : " + previewDetailsTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <Row>
            <Col>
              <span className="lable-style">Game: </span>
              {previewDetailsData?.inventoryId?.gameName}
            </Col>
            <Col>
              <span className="lable-style">Event: </span>
              {previewDetailsData?.event?.name}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Order#: </span>
              {previewDetailsData?.orderId?.orderId}
            </Col>
            <Col>
              <span className="lable-style">Cash Gift: </span>
              {previewDetailsData ? "$" + cashGift : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Sender: </span>
              <span>{previewDetailsData?.sender?.firstName} {previewDetailsData?.sender?.lastName}</span>
              <br />
              <span>{previewDetailsData?.sender?.email}</span>
              <br />
              <span>{previewDetailsData?.sender?.dialCode} {previewDetailsData?.sender?.phone}</span>
            </Col>
            <Col>
              <span className="lable-style">Recipient: </span>
              <span>{previewDetailsData?.recipient?.firstName} {previewDetailsData?.recipient?.lastName}</span>
              <br />
              <span>{previewDetailsData?.recipient?.email}</span>
              <br />
              <span>{previewDetailsData?.recipient?.dialCode} {previewDetailsData?.recipient?.phone}</span>
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Sender Nick Name: </span>{previewDetailsData?.senderNickName}
            </Col>
            <Col>
              <span className="lable-style">Recipient Nick Name: </span>{previewDetailsData?.recipientNickName}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Status: </span>
              {previewDetailsData ? gameStatus(previewDetailsData.status) : null}
            </Col>
            <Col>
              <span className="lable-style">Playing Status: </span>
              {previewDetailsData ? renderPlayingStatus(previewDetailsData.playingStatus) : null}
            </Col>
          </Row>
          <Row className="row-margin">
            <Col>
              <span className="lable-style">Created At: </span>
              {previewDetailsData ? moment(previewDetailsData.createdAt).format('MM-DD-YYYY hh:mm a') : null}
            </Col>
          </Row>
        </Modal>
      </Auxiliary>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    personalizationData: state.personalizationData
  }
}

function mapDispathToProps(dispatch) {
  return bindActionCreators({
    listPersonalizations,
    searchPersonalization,
    resetGamePersonalizations
  }, dispatch);
}

export default connect(mapStateToProps, mapDispathToProps)(GameTracking);
