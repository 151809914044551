import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from "./settings";
import Auth from "./auth";
import EventTitle from "./eventTitle";
import EventImage from "./eventImage";
import Interest from "./interest";
import EventSubCategory from "./eventSubCategory";
import VideoTutorial from "./videoTutorial";
import Location from "./location";
import Users from "./users";
import Roles from "./roles";
import Dashboard from "./dashboard";
import GameTemplate from "./gameTemplate";
import GreetingCategoryTag from "./greetingCatergoryTag";
import AppUsers from "./appUsers";
import GreetingPackage from './greetingPackage';
import Reports from './reports';
import SendDemo from './sendDemo';
import Faq from "./faq";
import Message from "./message";
import GameOrders from "./gameOrders";
import Personalization from "./personalization";
import GamesComplete from "./gamesComplete";

import Slides from "./marketing/slides";
import CustomerOrders from "./customerOrders";
import CustomerGamesReceived from "./customerGamesReceived";

// Game Catalog
import GameInventory from './gameCatalog/gameInventory';
import GameSubCategory from './gameCatalog/gameSubCategory';
import GameCategoryTag from './gameCatalog/gameCategoryTag';
import GameSpecialTag from './gameCatalog/specialTag';
import GameInfoTag from './gameCatalog/infoTag';

import Common from "./common";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  common: Common,
  settings: Settings,
  auth: Auth,
  eventTitle: EventTitle,
  eventImage: EventImage,
  interests: Interest,
  eventSubCategory: EventSubCategory,
  videoTutorial: VideoTutorial,
  locationData: Location,
  users: Users,
  roles: Roles,
  dashboard: Dashboard,
  gameTemplate: GameTemplate,
  greetingPackage: GreetingPackage,
  greetingCategoryTag : GreetingCategoryTag,
  appUsers: AppUsers,
  reports: Reports,
  sendDemoData: SendDemo,
  gameInventoryData: GameInventory,
  gameSubCategoryData: GameSubCategory,
  gameCategoryTagData: GameCategoryTag,
  gameSpecialTagData: GameSpecialTag,
  gameInfoTagData: GameInfoTag,
  faqs: Faq,
  messages: Message,
  gameOrders: GameOrders,
  personalizationData: Personalization,
  gamesCompleteData: GamesComplete,
  slidesData: Slides,
  customerOrdersData: CustomerOrders,
  customerGamesReceivedData: CustomerGamesReceived
});

export default createRootReducer;
