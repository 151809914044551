import React from "react";
import {Route, Switch} from "react-router-dom";

/** Roles */
import PrivateRoute from "./../shared/routePermission/privateRoute";
import { Roles } from "../shared/enums/roles";

/** Dashboard */
import Home from "./home/index";

/** Other Pages */
import EventTitle from "./eventTitle/index";
import EventImages from "./eventImages/index";
import EventSubCategory from "./eventSubCategory/index";
import Interests from "./interest/index";
import GameTemplate from "./gameTemplate/index";
import VideoTutorial from "./videoTutorial/index";
import Users from "./users/index";
import Legal from "./legal";
import GreetingCategoryTag from "./greetingCategoryTag";
import GreetingPackage from "./greetingPackage";
import AppUsers from "./appUsers";
import Reports from "./reports";
import GameCatalog from "./gameCatalog";
import Faq from "./faqs";
import Messages from "./messages";
import Marketing from "./marketing";

import GameOrders from "./gameOrders";
import GameTracking from "./gameTracking";
import GamesComplete from "./gamesComplete";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home`} component={Home}/>

      <PrivateRoute path={`${match.url}event-title`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={EventTitle}/>
      <PrivateRoute path={`${match.url}event-image`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={EventImages}/>
      <PrivateRoute path={`${match.url}event-subcategory`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={EventSubCategory}/>
      <PrivateRoute path={`${match.url}interest`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Interests}/>
      <PrivateRoute path={`${match.url}game-template`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.ContentAdmin, Roles.DevOpsAdmin]} component={GameTemplate}/>
      <PrivateRoute path={`${match.url}video-tutorial`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={VideoTutorial}/>
      <PrivateRoute path={`${match.url}users`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Users}/>
      <PrivateRoute path={`${match.url}legal`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Legal}/>
      <PrivateRoute path={`${match.url}greeting-category-tag`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={GreetingCategoryTag}/>
      <PrivateRoute path={`${match.url}greeting-package`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={GreetingPackage}/>
      <PrivateRoute path={`${match.url}customers`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={AppUsers}/>
      <PrivateRoute path={`${match.url}reports`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Reports}/>
      <PrivateRoute path={`${match.url}game-catalog`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={GameCatalog}/>
      <PrivateRoute path={`${match.url}faqs`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Faq}/>
      <PrivateRoute path={`${match.url}messages`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Messages}/>
      <PrivateRoute path={`${match.url}game-orders`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={GameOrders}/>
      <PrivateRoute path={`${match.url}game-tracking`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={GameTracking}/>
      <PrivateRoute path={`${match.url}games-complete`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={GamesComplete}/>
      <PrivateRoute path={`${match.url}marketing`} permission={[Roles.SuperAdmin, Roles.DevOpsSuperAdmin, Roles.DevOpsAdmin]} component={Marketing}/>
    </Switch>
  </div>
);

export default App;
