import {
  APP_USER
} from "../../constants/actionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  messageId: null,
  lists: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case APP_USER.RESET_APP_USERS: {
      return {
        ...state,
        loader: false,
        alertMessage: '',
        messageId: null
      }
    }

    case APP_USER.LIST_APP_USER_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case APP_USER.LIST_APP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case APP_USER.LIST_APP_USER_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case APP_USER.DELETE_APP_USER_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case APP_USER.DELETE_APP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 200,
        lists: action.payload.data
      }
    }

    case APP_USER.DELETE_APP_USER_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    case APP_USER.SEARCH_APP_USER_REQUEST: {
      return {
        ...state,
        loader: true
      }
    }

    case APP_USER.SEARCH_APP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        messageId: 204,
        lists: action.payload
      }
    }

    case APP_USER.SEARCH_APP_USER_FAIL: {
      return {
        ...state,
        loader: false,
        alertMessage: action.payload.message,
        messageId: 203
      }
    }

    default:
      return state;
  }
}
